@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --SectionHero_desktopTitleMaxWidth: 625px;

  --SectionHero_animation: {
    animation-name: animationTitle;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;

    visibility: hidden;
    opacity: 1;
    position: relative;
  }
}

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;

  /* Text positioning is done with paddings */
}

.heroContent {
  margin: 0 32px 0;

  @media (--viewportMedium) {
    margin: -160px 7.5vw 0 7.5vw;
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;
  }

  @media only screen and (min-width: 1367px) {
    margin: -160px auto 0 auto;
    max-width: calc(1052px + 36px + 36px);
    width: 100%;
    padding: 0 36px 0 36px;
  }
}

.heroMainTitle {
  /* @apply --marketplaceHeroTitleFontStyles; */
  color: #FFF;
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
  max-width: 300px;
  margin: 0 auto;
  opacity: 1;
  position: relative;
  text-align: center;
  margin-bottom: 16px;
  letter-spacing: 0.1px;

  @media (--viewportMedium) {
    max-width: 900px;
    font-size: 48px;
    line-height: 1.2;
    margin-bottom: 30px;
  }
}

.heroSubTitle {
  /* @apply --marketplaceBodyFontStyles; */
  position: relative;
  color: #FFF;
  margin: 0 auto 12px auto;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  opacity: 1;
  text-align: center;
  max-width: 280px;

  @media (--viewportMedium) {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.3;
    margin: 0 auto 20px auto;
    max-width: 580px;
  }
}

.heroButton {
  position: relative;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.4px;
  border: 1px solid #FFF;
  color: var(--matterColor);
  border-radius: 8px;
  height: 42px;
  font-weight: 500;
  padding: 9px 24px;
  text-decoration: none;
  background-color: #FFF;
  font-weight: 700;

  &:hover {
    text-decoration: none;
    background-color: #ededed;
  }
}

.heroForm {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;

  @media (--viewportMedium) {
    flex-flow: row nowrap;
  }
}

.heroForm > div {
  width: 100%;
  height: auto;

  @media (--viewportMedium) {
    width: unset;
  }
}

.heroForm .customSelectWrapper {
  width: 100%;

  @media (--viewportMedium) {
    width: unset;
  }
}

.heroForm .customSelectWrapper + .customSelectWrapper {
  margin-left: 0;
  margin-top: 15px;

  @media (--viewportMedium) {
    margin-left: 8px;
    margin-top: 0;
  }
}

.heroForm .customSelectInput {
  width: 100%;
  height: 47px;
  padding: 14px 20px;
  color: var(--contentColorPrimary);

  @media (--viewportMedium) {
    width: 240px;
    margin-bottom: 0;
  }
}

.customSelectInput::placeholder {
  color: var(--contentColorTertiary);
}

.customSelectInputIcon {
  display: none;
}

.submitButton {
  background-color: var(--marketplaceColor);
  width: 100%;
  height: 47px;
  margin-top: 14px;
  margin-left: 0;
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
  border: 0;
  font-weight: 700;
  cursor: pointer;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  @media (--viewportMedium) {
    width: 64px;
    height: 48px;
    margin-top: 0;
    border: 1px solid#FFF;
    border-radius: 0 8px 8px 0;
  }
}

.submitButton:hover {
  background-color: var(--marketplaceColorDark);
}

.submitButton:focus {
  outline: 0;
}

.customSelectWrapper {
  position: relative;
}

.customSelectInput {
  display: flex;
  align-items: center;
  width: 300px;
  height: 47px;
  padding: 20px;
  /* background: hsla(0,0%,100%,.27); */
  font-size: 16px;
  font-weight: 400;
  color: #FFF;
  border: 1px solid #FFF;
  cursor: pointer;
}

.heroForm .customSelectInput {
  width: 100%;
  height: 47px;
  padding: 14px 20px;

  @media (--viewportMedium) {
    width: 300px;
    height: 48px;
    padding: 20px;
    border-radius: 0;
  }

  &:first-child {
    border-radius: 8px;

    @media (--viewportMedium) {
      border-radius: 8px 0 0 8px;
    }
  }
}

.suggestionWrapper,
.locationWrapper {
  border-radius: 8px;
  background-color: #FFF;

  @media (--viewportMedium) {
    border-radius: 0;
  }
}

.locationWrapper:focus-within {
  border-radius: 8px 8px 0 0;

  @media (--viewportMedium) {
    border-radius: 0;
  }
}

.suggestionWrapper {
  border-radius: 8px;

  @media (--viewportMedium) {
    border-radius: 8px 0 0 8px;
  }
}

.suggestionWrapper:focus-within {
  border-radius: 8px 8px 0 0;

  @media (--viewportMedium) {
    border-radius: 8px 0 0 0;
  }
}

.customSelectInputFocus {
  border-radius: 8px 8px 8px 8px !important;
  border: 1px solid var(--marketplaceColor) !important;
  box-shadow: 0 6px 6px 0 rgba(0, 83, 125, 0.10);
}

.customSelectPlaceholder {
  color: #FFF;
}

.customSelectDropdown {
  position: absolute;
  top: 47px;
  left: 0;
  right: 0;
  background-color: #fff;
  border-radius: 0 0 10px 10px;
  border: 1px solid #E5E5E5;
  border-top: 0;
  padding-bottom: 20px;
  z-index: 2;

  @media (--viewportMedium) {
    top: 48px;
  }
}

.customSelectOptionWrapper {
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 45px;
  line-height: 45px;
  font-size: 16px;
  font-weight: 400;
}

.customSelectOptionWrapper img {
  max-height: 25px;
  max-width: 25px;
}

.customSelectOption {
  padding-left: 20px;
  flex-grow: 1;
}

.customSelectOptionWrapper:hover {
  background-color: var(--marketplaceColorLight);
  cursor: pointer;
}

.video {
  top: -72px;
  left: 0;
  right: 0;
  width: 100%;
  bottom: 0;
  z-index: -100;

  & .img {
    top: -72px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-size: cover;
    /*background-color: #000;*/
    background-position: 50% 50%;
    position: absolute;
    z-index: -100;
    background-image: url("bgMobile.jpg");

    @media(--viewportSmall) {
      background-image: url("background.jpeg");
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);

      &.huLanding {
        background-image: url("backgroundHu.jpg");
        -webkit-transform: unset;
        transform: unset;
      }

      &.czLanding {
        background-image: url("newBgCz.jpg");
        -webkit-transform: unset;
        transform: unset;
      }
    }
  }

  & .overlay {
    top: -72px;
    background-color: #000;
    opacity: .3;
    width: 100%;
    height: 100%;
    z-index: -99;
    position: absolute;
  }
}

:root {
  --star-size: 16px;
  --star-color: #fff;
  --star-background: #FFDF38;
}

.stars {
  --percent: calc(4.9 / 5 * 100%);

  display: inline-block;
  font-size: var(--star-size);
  font-family: Times;
  line-height: 1;
}

.stars::before {
  content: '★★★★';
  letter-spacing: 3px;
  background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  }

  .stars::after {
    --percentHalf: calc(2.1 / 5 * 100%);

    content: '★';
    letter-spacing: 3px;
    background: linear-gradient(90deg, var(--star-background) var(--percentHalf), var(--star-color) var(--percentHalf));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    }

.ratingsWrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  opacity: 1;
  position: relative;
  margin-bottom: 20px;
}

.rating {
  color: #fff;
  font-weight: 700;
  font-size: 18px;
}

.starsWrapper {
  display: flex;
  flex: "0 0 auto";
  margin: 0 10px;
}

.reviews {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
}

.scrollBtnWrapper {
  display: flex;
  justify-content: center;
  position: relative;
  bottom: -20px;

  @media (--viewportMedium) {
    bottom: -100px;
  }
}

.scrollBtn {
  height: 36px;
  width: 36px;
  border: 2px solid #FFF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.suggestions {
  @apply --marketplaceBodyFontStyles;

  margin-bottom: 8px;
  margin-top: 0;
  padding: 0;
  background-color: #FFF;

  & li {
    color: var(--matterColorLight);
    transition: var(--transitionStyleButton);
    margin: 0; /* Remove the double white space */
    line-height: 45px;
    font-size: 16px;
    font-weight: 400;
    color: #4a4a4a;

    /* Assign enough vertical padding to make the element at least 44px high */
    padding: 0 20px;

    @media (--viewportMedium) {
      margin: 0;
    }

    &:hover,
    &.highlighted {
      cursor: pointer;
      color: #4a4a4a;
      background-color: var(--marketplaceColorLight);
    }
  }
}

.submitButtonAlternativeA {
  background-color: #1f6ebd;
}

.submitButtonAlternativeB {
  background-color: #fbac07;
}

.heroMainTitleAlternative {
  /* @apply --marketplaceHeroTitleFontStyles; */
  color: #FFF;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  max-width: 300px;
  margin: 0 auto;
  opacity: 1;
  position: relative;
  text-align: center;
  margin-bottom: 16px;
  white-space: pre-wrap;

  @media (--viewportMedium) {
    max-width: 700px;
    font-size: 44px;
    line-height: 1.2;
    letter-spacing: 0.3px;
    margin-bottom: 30px;
  }
}

.predictions {
  margin-top: 0;
  top: 47px;

  @media(--viewportMedium) {
    top: 48px;
  }
}

.predictions,
.suggestionsWrapper {
  margin-top: 0;
  padding: 0 12px 12px 12px;
  box-shadow: 0px 6px 6px 0px rgba(0, 83, 125, 0.10);

  @media(--viewportSmall) {
    padding: 0;
  }
}

.predictions {
    padding-top: 12px;
}
